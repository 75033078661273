<template>
  <span data-v-056cdc84="" class="el-avatar el-avatar--small el-avatar--circle">
    <img :src="src" style="object-fit: cover;">
  </span>
</template>

<script>
export default {
  name: "GCAvatar",
  props: ['src'],
}
</script>

<style scoped>

</style>