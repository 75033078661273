<template>
  <div class="m-video" :class="{'u-video-hover': !hidden}" style="width: 670px; height: 450px;">
    <video
        ref="veo"
        style=" width: 100%; height: 100%; object-fit: cover;"
        :src="src"
        :poster="veoPoster"
        :width="width"
        :height="height"
        :autoplay="autoplay"
        :controls="!originPlay&&controls"
        :loop="loop"
        :muted="autoplay || muted"
        :preload="preload"
        crossorigin="anonymous"
        @loadeddata="poster ? () => false : getPoster()"
        @pause="showPlay ? onPause() : () => false"
        @playing="showPlay ? onPlaying() : () => false"
        @click.prevent.once="onPlay"
        v-bind="$attrs">
      您的浏览器不支持video标签。
    </video>
    <svg v-show="originPlay || showPlay" class="u-play" :class="{'hidden': hidden}" :style="`width: ${playWidth}px; height: ${playWidth}px;`" t="1716190812071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7981">
      <path d="M930.925 518.775c0 210.246-170.428 380.646-380.659 380.646-210.232 0-380.688-170.4-380.688-380.646 0-210.245 170.456-380.659 380.688-380.659 210.231 0 380.659 170.414 380.659 380.659" fill="#F7E946" p-id="7982"></path><path d="M513.888 73.273c-39.473 0-78.641 5.234-116.4 15.537a28.424 28.424 0 0 0-3.553 1.237c-0.729 0.136-1.458 0.292-2.186 0.487-33.977 9.295-72.846 25.719-103.7 42.872-13.645 7.61-18.562 24.833-10.966 38.478 5.193 9.294 14.806 14.529 24.75 14.529 4.64 0 9.363-1.146 13.728-3.563 26.876-14.957 61.493-29.656 91.104-37.749a28.49 28.49 0 0 0 3.497-1.211 28.407 28.407 0 0 0 2.242-0.499c32.897-8.991 67.039-13.548 101.484-13.548 211.861 0 384.223 172.348 384.223 384.196 0 211.859-172.361 384.223-384.223 384.223-211.834 0-384.168-172.363-384.168-384.223 0-57.358 12.32-112.519 36.6-163.952 6.657-14.115 0.635-30.978-13.507-37.648-14.143-6.671-30.992-0.635-37.648 13.507-27.873 59.029-42.016 122.325-42.016 188.094 0 243.06 197.719 440.792 440.738 440.792 243.046 0 440.793-197.732 440.793-440.792 0.001-243.034-197.746-440.767-440.792-440.767z" fill="#213847" p-id="7983"></path><path d="M167.092 295.079a28.099 28.099 0 0 1-16.491-5.331c-12.678-9.129-15.551-26.794-6.436-39.472a442.718 442.718 0 0 1 68.972-75.574c11.712-10.233 29.666-8.99 39.887 2.804 10.247 11.794 8.977 29.652-2.818 39.9a387.472 387.472 0 0 0-60.133 65.906c-5.524 7.679-14.197 11.767-22.981 11.767zM392.019 696.373a28.196 28.196 0 0 1-13.645-3.509 28.287 28.287 0 0 1-14.64-24.776V359.991a28.288 28.288 0 0 1 14.64-24.777 28.239 28.239 0 0 1 28.754 0.87l243.765 154.063a28.275 28.275 0 0 1 13.176 23.907 28.255 28.255 0 0 1-13.176 23.906L407.128 691.994a28.279 28.279 0 0 1-15.109 4.379z m28.284-285.047v205.425l162.53-102.698-162.53-102.727z" fill="#213847" p-id="7984"></path>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Video',
  props: {
    src: { // 视频文件url，必传，支持网络地址 https 和相对地址 require('@/assets/files/Bao.mp4')
      type: String,
      required: true,
      default: ''
    },
    poster: { // 视频封面url，支持网络地址 https 和相对地址 require('@/assets/images/Bao.jpg')
      type: String,
      default: ''
    },
    second: { // 在未设置封面时，自动截取视频第 second 秒对应帧作为视频封面
      type: Number,
      default: 0.5
    },
    width: { // 视频播放器宽度
      type: Number,
      default: 800
    },
    height: { // 视频播放器高度
      type: Number,
      default: 450
    },

    autoplay: { // 视频就绪后是否马上播放
      type: Boolean,
      default: false
    },
    controls: { // 是否向用户显示控件，比如进度条，全屏
      type: Boolean,
      default: true
    },
    loop: { // 视频播放完成后，是否循环播放
      type: Boolean,
      default: false
    },
    muted: { // 是否静音
      type: Boolean,
      default: false
    },
    preload: { // 是否在页面加载后载入视频，如果设置了autoplay属性，则preload将被忽略；
      type: String,
      default: 'metadata' // auto:一旦页面加载，则开始加载视频; metadata:当页面加载后仅加载视频的元数据 none:页面加载后不应加载视频
    },
    showPlay: { // 播放暂停时是否显示播放器中间的暂停图标
      type: Boolean,
      default: true
    },
    playWidth: { // 中间播放暂停按钮的边长
      type: Number,
      default: 70
    },
    zoom: { // video的poster默认图片和视频内容缩放规则
      type: String,
      default: 'cover' // none:(默认)保存原有内容，不进行缩放; fill:不保持原有比例，内容拉伸填充整个内容容器; contain:保存原有比例，内容以包含方式缩放; cover:保存原有比例，内容以覆盖方式缩放
    }
  },
  data () {
    return {
      veoPoster: this.poster,
      originPlay: true,
      hidden: false
    }
  },
  mounted () {
    if (this.autoplay) {
      this.hidden = true
      this.originPlay = false
    }
    /*
      自定义设置播放速度，经测试：
      在vue2中需设置：this.$refs.veo.playbackRate = 2
      在vue3中需设置：veo.value.defaultPlaybackRate = 2
    */
    // this.$refs.veo.playbackRate = 2
  },
  methods: {
    /*
      loadeddata 事件在媒体当前播放位置的视频帧（通常是第一帧）加载完成后触发
      preload为none时不会触发
    */
    getPoster () { // 在未设置封面时，自动获取视频0.5s对应帧作为视频封面
      // 由于不少视频第一帧为黑屏，故设置视频开始播放时间为0.5s，即取该时刻帧作为封面图
      this.$refs.veo.currentTime = this.second
      // 创建canvas元素
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // canvas画图
      canvas.width = this.$refs.veo.videoWidth
      canvas.height = this.$refs.veo.videoHeight
      ctx.drawImage(this.$refs.veo, 0, 0, canvas.width, canvas.height)
      // 把canvas转成base64编码格式
      this.veoPoster = canvas.toDataURL('image/png')
    },
    onPlay () {
      if (this.originPlay) {
        this.$refs.veo.currentTime = 0
        this.originPlay = false
      }
      if (this.autoplay) {
        this.$refs.veo.pause()
      } else {
        this.hidden = true
        this.$refs.veo.play()
      }
    },
    onPause () {
      this.hidden = false
    },
    onPlaying () {
      this.hidden = true
    }
  }
}
</script>
<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.m-video {
  display: inline-block;
  position: relative;
  background: #000;
  cursor: pointer;

  .u-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    fill: none;
    color: #FFF;
    pointer-events: none;
    opacity: 0.7;
    transition: opacity .3s;
    transform: translate(-50%, -50%);
    path {
      stroke: #FFF;
    }
  }
  .hidden {
    opacity: 0;
  }
}

.u-video-hover {
  &:hover {
    .u-play {
      opacity: 0.9;
    }
  }
}
</style>
