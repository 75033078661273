<template>
  <div class="gc-footer">
    <p>©lilliab 邮箱：lilliab@163.com</p>
    <a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2024020483号-1</a>
  </div>
</template>

<script>
export default {
name: "GCFooter"
}
</script>

<style scoped>
.gc-footer {
  padding: 20px;
  background-color: #2D2F33;
}
.gc-footer p {
  text-align: center;
  font-size: 15px;
  color: #AAAAAA;
}
</style>
