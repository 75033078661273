<template>
  <div class="post-cards">

    <el-card class="no-post" v-show="postCardData.length===0">
      <img width="200px" src="../assets/img/noPost.png"/>
      <p>暂时没有图哦</p>
    </el-card>
    <el-row>
      <el-col :span="8" v-for="post in postCardData" :key="post.postId" :offset="0" style="margin-bottom: 20px">
        <el-card :body-style="{ padding: '0px' }" class="custom-card">
          <div slot="header" class="clearfix" v-if="isNotEmpty(title)">
            <span>{{ title }}</span>
          </div>
          <div style="position: relative;"  @click="toPage({name:'post', query: {postId: post.postId}})">
            <img :src="post.postImgUrls[0]" class="image">
            <div style="position: absolute; bottom: 5px; right: 7px; background-color: rgba(0,0,0,0.5); color: white; padding: 5px; font-size: 13px">
              <i class="el-icon-folder-opened"></i>+{{ post.postImgUrls.length }}
            </div>
          </div>
          <div style="padding: 14px;">
            <div class="post-title">
              <span  @click="toPage({name:'post', query: {postId: post.postId}})" >{{ post.postTitle }}</span>
            </div>
            <div class="bottom clearfix">
              <div class="post-user"
                   @mouseenter="changeOnMouseId(post.postId)"
                   @mouseleave="changeOnMouseId(-1)"
                   @click="toPage({name:'user', query: {userId: post.userId}})">
                <g-c-avatar :src="post.userImgUrl"></g-c-avatar>
                <span :class="{'on-mouse': (onMouseId===post.postId)}"
                      style="margin-left: 10px;font-size: 14px">{{ post.userName }}</span>
              </div>
              <div class="post-likes">
                <i @click="clickLike(post)" class="el-icon-like clickable"
                   :class="{'liked':post.postLikeFlag,'clickable-flag':!clickable}"></i>&nbsp;
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card v-show="postCardData.length!==0">
      <el-link
          @click="getMorePostData" :disabled="isAllPost"
          type="primary" :underline="false" style="width: 20%;margin-left: 40%">
        {{getMorePostText}}
      </el-link>
    </el-card>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {getTime, getPartitionName, isNotEmpty} from "@/uitls";
import {likePost, cancelLikePost} from "@/api";

export default {
  name: "ImgPostCard",
  props: ["postCardData", "title" ,"isAllPost"],
  computed: {
    ...mapState(['BASEURL', 'defaultUserAvatar', 'loginUser']),
    getMorePostText: {
      get: function (){
        return this.isAllPost ? "已经到底了" : "点击加载更多";
      }
    }
  },
  data() {
    return {
      hasBlurFlag: false,
      onMouseId: -1,
      onMousePostId: -1,
      action: {},
      clickable: true,
      currentDate: new Date(),
    }
  },
  methods: {
    ...mapMutations(['toPage']),
    async clickLike(post) {
      post.postLikeFlag = !post.postLikeFlag;
      this.clickable = false;
      this.action.postId = post.postId;
      this.action.actionCategoryId = 1;
      let result;
      if (post.postLikeFlag) {
        post.postLikesNum++;
        result = await likePost(this.action);
      } else {
        post.postLikesNum--;
        result = await cancelLikePost(this.action);
      }
      this.clickable = true;
    },
    isNotEmpty(data) {
      return isNotEmpty(data);
    },
    hasBlur() {
      this.hasBlurFlag = true;
    },
    changeOnMouseId(val) {
      this.onMouseId = val;
    },
    changeOnMousePostId(val) {
      this.onMousePostId = val;
    },
    getTime(createTime) {
      return getTime(createTime);
    },
    getPartitionName(partitionId) {
      return getPartitionName(partitionId);
    },
    getMorePostData() {
      this.$emit("getMoreData");
    },
    isLogin(userId) {
      return this.loginUser.userId === userId;
    }
  }
}
</script>

<style scoped>

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  display: flex;
  margin-top: 5px;
  line-height: 12px;
  justify-content: space-between;
  align-items: center;
}



.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 150px;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.1);
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.custom-card {
  width: 220px;  /* 调整卡片的宽度 */
  height: 224px; /* 调整卡片的高度 */
  cursor: pointer;
}
.clickable-flag{
  cursor: default;
  pointer-events: none;
}
.clickable {
  cursor: pointer;
  opacity: 0.5;
}

.post-likes {
  margin-left: auto;
}

.liked {
  color: #35D0FF;
}

.no-post {
  align-content: center;
  color: #999999;
  font-size: 14px;
  text-align: center;
  min-height: 400px;
  padding-top: 150px;
}

.post-data {
  float: right;
}

.post-data > div {
  float: left;
  color: #CCCCCC;
  width: 80px;
  font-size: 14px;
}

.post-data > div > i {
  font-size: 16px;
}

.float-left {
  float: left;
  margin-right: 5px;
}

.post-img {
  margin: 10px 0;
}

.post-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #999999;
}


.post-title {
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-flex {
  display: flex;
  align-items: center;
}

.post-user {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}
.post-user:hover {
  transform: scale(1.1);
}

.on-mouse {
  color: #00C3FF;
}
</style>
