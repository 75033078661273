<template>
  <div style="padding: 10px; margin-bottom: 50px">
    <el-row>
      <el-col :span="8">
        <el-card style="width: 100%; min-height: 300px; color: #333">
          <div style="padding-bottom: 10px; border-bottom: 1px solid #ccc">在线用户<span style="font-size: 12px">（点击聊天气泡开始聊天）</span></div>
          <div style="padding: 10px 0" v-for="user in users" :key="user.username">
            <span>{{ user.username }}</span>
            <i class="el-icon-chat-dot-round" style="margin-left: 10px; font-size: 16px; cursor: pointer"
               @click="renderContent(user.username)"></i>
            <span style="font-size: 12px;color: limegreen; margin-left: 5px" v-if="user.username === chatUser">chatting...</span>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <div style="width: 800px; margin: 0 auto; background-color: white;
                    border-radius: 5px; box-shadow: 0 0 10px #ccc">
          <div style="text-align: center; line-height: 50px;">
            大别野（{{ chatUser }}）
          </div>
          <div style="height: 350px; overflow:auto; border-top: 1px solid #ccc" v-html="content"></div>
          <div style="height: 200px">
            <textarea v-model="text" style="height: 160px; width: 760px; padding: 20px; border: none; border-top: 1px solid #ccc;
             border-bottom: 1px solid #ccc; outline: none"></textarea>
            <div style="text-align: right; padding-right: 10px">
              <el-button type="primary" size="mini" @click="send">发送</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>

let socket;
export default {
  name: "Chat",
  data() {
    return {
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      user: {},
      isCollapse: false,
      users: [],
      chatUser: '',
      text: "",
      messages: [],
      content: '',
      socket: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    send() {
      if (!this.chatUser) {
        this.$message({type: 'warning', message: "请选择聊天对象"})
        return;
      }
      if (!this.text) {
        this.$message({type: 'warning', message: "请输入内容"})
      } else {
        if (typeof (WebSocket) == "undefined") {
          console.log("您的浏览器不支持WebSocket");
        } else {
          console.log("您的浏览器支持WebSocket");
          let message = {from: this.loginUser.userName , to: this.chatUser, text: this.text}
          console.log(message);
          this.socket.send(JSON.stringify(message));
          this.setChatMessage(message, this.chatUser)

          this.messages.push({user: this.loginUser.userName, text: this.text})
          this.createContent(null, this.loginUser.userName, this.text)
          this.text = '';
          // 刷新聊天界面
          this.renderContent(this.chatUser);
        }
      }
    },
    createContent(remoteUser, nowUser, text) {
      let html
      if (nowUser === this.loginUser.userName) {
        html = "<div class=\"el-row\" style=\"padding: 5px 0\">\n" +
            "  <div class=\"el-col el-col-2\" style=\"text-align: right\">\n" +
            "  <span class=\"el-avatar el-avatar--circle\" style=\"height: 40px; width: 40px; line-height: 40px;\">\n" +
            "    <img src=\"http://upload-yls.lilliab.com/image/avatar/official/defaultUserAvatar.png \" style=\"object-fit: cover;\">\n" +
            "  </span>\n" +
            "  </div>\n" +
            "  <div class=\"el-col el-col-22\" style=\"text-align: left; padding-left: 10px\">\n" +
            "    <div class=\"tip right\">" + text + "</div>\n" +
            "  </div>\n" +
            "</div>";
      } else if (remoteUser) {
        html = "<div class=\"el-row\" style=\"padding: 5px 0\">\n" +
            "  <div class=\"el-col el-col-22\" style=\"text-align: right; padding-right: 10px\">\n" +
            "    <div class=\"tip left\">" + text + "</div>\n" +
            "  </div>\n" +
            "  <div class=\"el-col el-col-2\">\n" +
            "  <span class=\"el-avatar el-avatar--circle\" style=\"height: 40px; width: 40px; line-height: 40px;\">\n" +
            "    <img src=\"" + this.loginUser.userImgUrl + "\" style=\"object-fit: cover;\">\n" +
            "  </span>\n" +
            "  </div>\n" +
            "</div>";
      }
      console.log(" 111111111111111111",)
      this.content += html;
    },
    renderContent(chatUser) {
      this.chatUser = chatUser

      let chat = localStorage.getItem("chat") ? JSON.parse(localStorage.getItem("chat")) : {};
      let messages = chat[chatUser] ? chat[chatUser] : [];
      this.content = '';
      for(let i = 0; i < messages.length; i++) {
        this.createContent(messages[i].from, messages[i].to, messages[i].text)
      }
    },
    setChatMessage(message, key) {
      let chat = localStorage.getItem("chat") ? JSON.parse(localStorage.getItem("chat")) : {};
      console.error(chat[key], message, chat[key] instanceof Array)
      if (chat[key] instanceof Array) {
        chat[key].push(message)
      } else {
        chat[key] = [message]
      }

      localStorage.setItem("chat", JSON.stringify(chat));
    },
    init() {
      // this.User = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User")) : {}
      this.loginUser = sessionStorage.loginUser ? JSON.parse(sessionStorage.loginUser) : {}
      let userName = this.loginUser.userName;
      let _this = this;
      if (typeof (WebSocket) == "undefined") {
        console.log("您的浏览器不支持WebSocket");
      } else {
        console.log("您的浏览器支持WebSocket");
        console.log(sessionStorage);
        let socketUrl = "ws://localhost:10241/imserver/" + userName;
        if (_this.socket != null) {
          _this.socket.close();
          _this.socket = null;
        }
        // 开启一个websocket服务
        _this.socket = new WebSocket(socketUrl);
        //打开事件
        _this.socket.onopen = function () {
          console.log("websocket已打开");
        };
        _this.socket.onmessage = function (msg) {
          console.log("收到数据====" + msg.data)

          let data = JSON.parse(msg.data)
          console.log(data)
          console.log('data.from', data.from, 'chatuser', _this.chatUser, data.from === _this.chatUser)
          if (data.users) {  // 获取在线人员信息
            _this.users = data.users.filter(user => user.username !== userName)
          }

          data = {
            ...data,
            to: userName
          }

          if(data.from !== undefined) {
            _this.setChatMessage(data, data.from)
          }

        };
        //关闭事件
        _this.socket.onclose = function () {
          console.log("websocket已关闭");
        };
        //发生了错误事件
        _this.socket.onerror = function () {
          console.log("websocket发生了错误");
        }
      }
    }
  }
}
</script>
<style>
.tip {
  color: white;
  text-align: center;
  border-radius: 10px;
  font-family: sans-serif;
  padding: 10px;
  width:auto;
  display:inline-block !important;
  display:inline;
}
.right {
  background-color: deepskyblue;
}
.left {
  background-color: forestgreen;
}
</style>
